.App {
  text-align: center;
  font-family: 'Lato', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #010d29;
}

.App .card {
  display: block;
}

.card-img-top {
  width: 90% !important;
  margin-top: 10px;
}

p, h1 {
  color: #ededed;
  max-width: 750px;
  display: block;
  margin: auto;
}


ol, ul ,li, p {
  font-size: large;
  text-align: left;
  color: #ededed;
}

p {
  font-size: large;
  margin-bottom: 1rem;
}

.controls {
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: .5rem;
  height: .5rem;
  border-radius: 100%;
  padding: 5px;
  margin-left: 8px;
  color: white;
  border: 3px solid white;
}

.line-it {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.header {
  margin-top: 35px;
  margin-bottom: 10px;
}

.footer {
  margin-top: 75px;
}

.footer-wrapper {
  margin-top: 100px;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.secondary {
  color: #1a74f4;
  margin-bottom: 25px;
  text-align: center;
}

.tag {
  margin-bottom: 25px;
}

.button:hover {
  background-color: #5F9EA0;
  border-color: #5F9EA0;
}

.App .card {
  width: 100%;
  margin-bottom: 20px;
  min-width: 240px;
  --bs-card-bg: #011839;
}

.App .card.active {
  width: 100%;
  margin-bottom: 20px;
  min-width: 240px;
  --bs-card-bg: #063270;
}

.App .card-title {
  color: white;
}

.App .btn {
  background-color: #174a9d;
  border-color: #174a9d;
  display: flex;
  white-space:nowrap;
  margin: 5px;
}

.mobile {
  display: none;
}


@media (max-width: 767.98px) {
  .App .btn {
    padding: 7px;
    margin: 3px;
  }

  .line-it {
    display: flex;
    flex-direction: column;
  }

  .App .card-title {
    white-space: nowrap;
    font-size: 16px;
  }

  .App .card {
    width: 100%;
    margin-bottom: 20px;
    min-width: 0;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}


.theTitle {
  font-weight: 500;
  line-height: 1.2;
  font-size: 22px;
}
